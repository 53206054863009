import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/shared/auth/user.service';

@Component({
  selector: 'app-api-modal',
  templateUrl: './api-modal.component.html',
  styleUrls: ['./api-modal.component.scss']
})
export class ApiModalComponent implements OnInit {

  @Input() public id;
  data: any

  constructor(public activeModal: NgbActiveModal, private userService: UserService) { }

  ngOnInit(): void {
    this.getResponse()
  }

  getResponse(){
    this.userService.getApiResponse(this.id).subscribe(
      res => {
        console.log(res);
        this.data = res
      },
      err => {
        console.log('error: ' + err)
      }
    );
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
