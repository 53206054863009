import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public selectedApp: any = null
  private subject = new Subject<any>();
  private userEvent = new Subject<any>();
  private registerEvent = new Subject<any>();
  private reloadEvent = new Subject<any>();

  //------------------//

  private selectedAppSource = new BehaviorSubject(this.selectedApp); 
  currentSelectedAppSource = this.selectedAppSource.asObservable();
  
  constructor(){}

  updateSelectedApp(selected: any){
    this.selectedAppSource.next(selected); 
  }
  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }

  // User Event

  sendUserEvent() {
    this.userEvent.next();
  }
  getUserEvent(): Observable<any>{ 
    return this.userEvent.asObservable();
  }

  // ----------------------- //

  // Register Event

  sendRegisterEvent() {
    this.registerEvent.next();
  }
  getRegisterEvent(): Observable<any>{ 
    return this.registerEvent.asObservable();
  }

  // ----------------------- //

  // Reload Event

  sendReloadEvent() {
    this.reloadEvent.next();
  }
  getReloadEvent(): Observable<any>{ 
    return this.reloadEvent.asObservable();
  }

  // ----------------------- //
}