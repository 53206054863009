<div class="modal-header">
    <h4 class="modal-title">Add New User</h4>
    <button type="button" class="close" aria-label="Close"
     (click)="activeModal.dismiss('No')">
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="registerForm">
      <div class="form-group">
        <input type="text" formControlName="name" class="form-control mb-2" placeholder="Name"
          [ngClass]="{ 'is-invalid': registerFormSubmitted && rf.name.invalid, 'is-valid': registerFormSubmitted && !rf.name.invalid }"
          required>
        <div *ngIf="registerFormSubmitted && (rf.name.invalid || rf.name.errors?.required)"
          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Name is
          required</div>
      </div>
      <div class="form-group">
        <input type="email" formControlName="email" class="form-control mb-2" placeholder="Email"
          [ngClass]="{ 'is-invalid': registerFormSubmitted && rf.email.invalid, 'is-valid': registerFormSubmitted && !rf.email.invalid }"
          required>
        <div *ngIf="registerFormSubmitted && (rf.email.errors?.required)"
          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Email is
          required</div>
        <div *ngIf="registerFormSubmitted &&  rf.email.errors?.email" class="help-block mt-1 text-danger"> <i
            class="ft-alert-circle align-middle"></i> Email must be a valid email address</div>
      </div>
      <div class="form-group">
        <input type="password" formControlName="password" class="form-control mb-2" placeholder="Password"
          [ngClass]="{ 'is-invalid': registerFormSubmitted && rf.password.invalid, 'is-valid': registerFormSubmitted && !rf.password.invalid }"
          required>
        <div *ngIf="registerFormSubmitted && (rf.password.errors?.required)"
          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Password is
          required
        </div>
        <div *ngIf="registerFormSubmitted && (!rf.password.errors?.required && rf.password.errors?.minlength)"
          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Password must be
          at least 6 characters
        </div>
      </div>
      <div class="form-group">
        <input type="password" formControlName="confirmPassword" class="form-control mb-2"
          placeholder="Confirm Password"
          [ngClass]="{ 'is-invalid': registerFormSubmitted && rf.confirmPassword.invalid, 'is-valid': registerFormSubmitted && !rf.confirmPassword.invalid }"
          required>
        <div
          *ngIf="registerFormSubmitted && (rf.confirmPassword.errors?.required)"
          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Confirm Password is
          required
        </div>
        <div *ngIf="registerFormSubmitted && (rf.confirmPassword.errors?.mustMatch)"
          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Passwords must
          match
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="onSubmit()">
      Create
    </button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">
      Cancel
    </button>
</div>