<div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close"
     (click)="activeModal.dismiss('No')">
    </button>
  </div>
  <div class="modal-body">
      <h5 class="text-center">{{modalMsg}}</h5>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="closeModal()">
      Yes
    </button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss('No')">
      Close
    </button>
</div>