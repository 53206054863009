import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/shared/auth/shared.service';
import { StorageService } from 'app/shared/auth/storage.service';
import { UserService } from 'app/shared/auth/user.service';
import { environment } from 'environments/environment';
declare var $:any

@Component({
  selector: 'app-create-app-modal',
  templateUrl: './create-app-modal.component.html',
  styleUrls: ['./create-app-modal.component.scss']
})
export class CreateAppModalComponent implements OnInit {
  
  appFormSubmitted = false;
  btnDisable = false;
  appForm: FormGroup;
  app: any

  constructor(public activeModal: NgbActiveModal, private storageService: StorageService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder, private userService: UserService) {
      this.appForm = this.formBuilder.group({
        name: ['', Validators.required],
      }
    );
  }

  get af() {
    return this.appForm.controls;
  }

  ngOnInit(): void {
  }

  //  On submit click, reset field value
  onSubmit() {
    this.appFormSubmitted = true;
    if (this.appForm.invalid) {
      return;
    }

    this.userService.createApp({ name: this.af.name.value }).subscribe(
      res => {
        console.log(res);
        this.sharedService.updateSelectedApp(res)
        this.storageService.setItem(environment.storage.selectedApp, res)
        this.sharedService.sendClickEvent()
        this.app = res
        this.appFormSubmitted = false
        this.btnDisable = true
        // this.activeModal.close()
      },
      err => {
        console.log('error: ' + err)
      }
    );
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  copyClientKey(){
    $('#client_key').select()
    document.execCommand('copy');
  }

  copyClientSecret(){
    $('#client_secret').select()
    document.execCommand('copy');
  }

}
