import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/shared/auth/user.service';
import { MustMatch } from 'app/shared/directives/must-match.validator';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent implements OnInit {
  
  registerFormSubmitted = false;
  registerForm: FormGroup;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private userService: UserService) {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  ngOnInit(): void {}


  get rf() {
    return this.registerForm.controls;
  }


  //  On submit click, reset field value
  onSubmit() {
    this.registerFormSubmitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    let data: any = {
      name: this.rf.name.value,
      email: this.rf.email.value,
      password: this.rf.password.value,
      password_confirmation: this.rf.confirmPassword.value,
    }

    this.userService.register(data).subscribe(
      res => {
        console.log(res);
        this.activeModal.close(1);
      },
      err => {
        console.log('error: ' + err)
      }
    );
  }

}
