import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor() {
    super();
  }

  login(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'admin/login', this.getBody(data), { headers: this.headers });
  }

  superAdminLogin(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'admin/super-login', this.getBody(data), { headers: this.headers });
  }

  register(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'admin/register', this.getBody(data), { headers: this.headers });
  }

  forgetPassword(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'admin/forget-password', this.getBody(data), { headers: this.headers });
  }

  resetPassword(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'admin/reset-password', this.getBody(data), { headers: this.headers });
  }

  createApp(data): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'admin/apps', this.getBody(data), { headers: this.headers });
  }

  getApps(params?): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'admin/apps', { params: this.getParams(params) });
  }

  deleteApps(id): Observable<any> {
    return this.http.delete<any>(this.apiUrl + 'admin/apps/'+id );
  }

  updateApp(id , data): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'admin/apps/'+id, data, { headers: this.headers });
  }

  updateUser(data): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'admin/users', data, { headers: this.headers });
  }

  getReport(id, params?){
    return this.http.get<any>(this.apiUrl + 'admin/apps/'+id+'/report', { params: this.getParams(params) });
  }

  getApiLogs(id, params?){
    return this.http.get<any>(this.apiUrl + 'admin/apps/'+id+'/logs', { params: this.getParams(params) });
  }

  getSuperApiLogs(params?){
    return this.http.get<any>(this.apiUrl + 'admin/logs', { params: this.getParams(params) });
  }

  getApiResponse(id, params?){
    return this.http.get<any>(this.apiUrl + 'admin/logs/'+id, { params: this.getParams(params) });
  }

  getUsers(params?){
    return this.http.get<any>(this.apiUrl + 'admin/users', { params: this.getParams(params) });
  }

  updateExistingUser(id, data): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'admin/users/'+id, data, { headers: this.headers });
  }

  deleteUser(id): Observable<any> {
    return this.http.delete<any>(this.apiUrl + 'admin/users/'+id );
  }

  uploadUserImage(file, id){
    return this.http.post<any>(this.apiUrl + 'admin/users/'+id+'/upload-picture', this.getBody(file), { headers: this.headers });
  }

  uploadAppImage(file, id){
    return this.http.post<any>(this.apiUrl + 'admin/apps/'+id+'/upload-picture', this.getBody(file), { headers: this.headers });
  }
}

