import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAppModalComponent } from './create-app-modal/create-app-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiModalComponent } from './api-modal/api-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { AddUserModalComponent } from './add-user-modal/add-user-modal.component';



@NgModule({
  declarations: [CreateAppModalComponent, ApiModalComponent, ConfirmationModalComponent, AddUserModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule ,
  ],
  exports: [CreateAppModalComponent]
})
export class ModalsModule { }
