import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { environment } from 'environments/environment';
import { JwtService } from './jwt.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router,
    private storageService: StorageService, private jwtService: JwtService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let userData = this.storageService.getItem(environment.storage.userData);
    if (userData) {
      // verify token
      console.log(userData)
      if (!this.jwtService.isTokenExpired(userData.token.access_token, 0)) {
        console.log("Token not Expired")
        // this.router.navigate(['/dashboard']);
        return true;
      } else {
        console.log("Token Expired")
        this.router.navigate(['/pages/login']);
      }
    } else {
      console.log("No Userdata")
      this.router.navigate(['/pages/login']);
    }
  }
}
