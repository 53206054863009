// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { ServiceInjector } from '../libraries/serviceInjector';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from '../storage.service';
import { Injectable } from '@angular/core';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  // private bsModalService: BsModalService;
  // private bsModalRef: BsModalRef;
  private tokenKey: string;
  // private storageService: StorageService;
  constructor(public storageService: StorageService) {
    // this.bsModalService = ServiceInjector.injector.get(BsModalService);
    this.tokenKey = environment.storage.userData;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:one-variable-per-declaration
    const userData = this.storageService.getItem(this.tokenKey);
    // tslint:disable-next-line:prefer-const
    let httpHeaders: any = {};
    if (userData && userData.token.access_token) {
      httpHeaders.Authorization = `Bearer ${userData.token.access_token}`;
      request = request.clone({
        url: request.url,
        setHeaders: httpHeaders
      });
    }
    return next.handle(request);
  }
}
