<div class="modal-header">
    <h4 class="modal-title">API Data</h4>
    <button type="button" class="close" aria-label="Close"
     (click)="activeModal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
      <div class="row" *ngIf="data">
        <div class="col-md-6 mb-3 mb-md-0">
          <h5 class="mb-3" style="font-weight: 600;">Request</h5>
          <pre class="mb-0 p-2 border bg-dark text-white" style="border-radius: 20px; max-height: 400px;">{{data?.request | json}}</pre>
        </div>
        <div class="col-md-6">
          <h5 class="mb-3" style="font-weight: 600;">Response</h5>
          <pre class="mb-0 p-2 border bg-dark text-white" style="border-radius: 20px; max-height: 400px;">{{data?.response | json}}</pre>
        </div>
      </div>
      <div class="row" *ngIf="!data">
        <div class="col-12">
          <p class="mb-0 text-center">Fetching Data...</p>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="activeModal.dismiss('Close clicked')">
      Close
    </button>
</div>