<div class="modal-header">
    <h4 class="modal-title">Create App</h4>
    <button type="button" class="close" aria-label="Close"
     (click)="activeModal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="appForm">
        <div class="form-group">
          <input type="text" formControlName="name" class="form-control mb-2" placeholder="Name"
            [ngClass]="{ 'is-invalid': appFormSubmitted && af.name.invalid, 'is-valid': appFormSubmitted && !af.name.invalid }"
            required>
          <div *ngIf="appFormSubmitted && (af.name.invalid || af.name.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Name is
            required</div>
        </div>
    </form>
    <div *ngIf="app">
      <h6>Client Key</h6>
      <div class="key mb-2 position-relative">
        <input class="form-control mb-0 text-truncate w-100 pr-5" id="client_key" style="pointer-events: none;
        opacity: 0.7" value="{{app?.client_key}}">
        <div class="copy" style="position: absolute; top: 0; right: 0;">
          <button class="btn" (click)="copyClientKey()"><i class="ft-copy"></i></button>
        </div>
      </div>
      <h6>Client Secret</h6>
      <div class="key mb-2 position-relative">
        <input class="form-control mb-0 text-truncate w-100 pr-5" id="client_secret" style="pointer-events: none;
        opacity: 0.7" value="{{app?.client_secret}}">
        <div class="copy" style="position: absolute; top: 0; right: 0;">
          <button class="btn" (click)="copyClientSecret()"><i class="ft-copy"></i></button>
        </div>
      </div>
      <p class="mb-0" style="font-size: 12px;font-weight: 600;">Note: Kindly copy Client Secret to safe place. It'll not be available after this popup closes</p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="activeModal.dismiss('Close clicked')">
      Close
    </button>
    <button class="btn btn-primary" [disabled]="btnDisable" (click)="onSubmit()">
        <i class="fa fa-spinner fa-spin mr-1" *ngIf="appFormSubmitted"></i>Create
    </button>
</div>