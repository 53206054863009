import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router) {

  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let userData = this.storageService.getItem(environment.storage.userData);
    let user = userData.user
    if (userData) {
      if(user.role_id == 1){
        console.log("Access Guaranted")
        return true
      }
      else{
        console.log("Rerouting...")
        this.router.navigate(['/dashboard']);
      }
    }
    else {
      console.log("No Userdata")
      this.router.navigate(['/pages/login']);
    }
  }
}
